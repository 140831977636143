import React from 'react';
import { PopupModal } from 'react-calendly';
import './styles.css';

class PopUpButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    return (
      <div>
        <button
          className="customButton"
          onClick={() => this.setState({ isOpen: true })}
        >
          Schedule A Demo
        </button>
        <PopupModal
          url="https://calendly.com/shestopal-mikesmobile/demo-30-minutes"
          onModalClose={() => this.setState({ isOpen: false })}
          open={this.state.isOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById('root')}
        />
      </div>
    );
  }
}

export default PopUpButtonComponent;
