import React from 'react';
import { PopupWidget } from 'react-calendly';

const CalendlyPopUpComponent = () => {
  return (
    <div className="App">
      <PopupWidget
        variant="outlined"
        url="https://calendly.com/shestopal-mikesmobile/demo-30-minutes"
        rootElement={document.getElementById('root')}
        text="Schedule a Demo"
        textColor="#ffffff"
        color="#AD8251"
      />
    </div>
  );
};

export default CalendlyPopUpComponent;
